export const COMPEON_PARTNER = 'compeon-partner';
export const CONSIDER_SUBSIDIES = 'consider-subsidies';
export const STATUS = 'status';

/*
 * Section names
 */

export const CORONA_STEP = 'coronaStep';
export const FINANCING_NEED = 'financingNeed';
export const COMPANY_DETAILS = 'companyDetails';
export const REQUEST_DETAILS = 'financingDetails';
export const INQUIRY_LIST = 'inquiryList';
export const COMPANIES_LIST = 'companiesList';
export const USER_PROFILE = 'userProfile';
export const SUMMARY = 'summary';
export const RENTENBANK_SUMMARY = 'rentenbankSummary';
export const FINAL_PAGE = 'finalPage';
export const REGISTRATION = 'registration';
export const SUBSIDY_FORESTRY_COMPANIES = 'subsidyForestryCompanies';
export const CONTACT_PERSON_STEP = 'companyInformationStep';
export const RENTENBANK_FINAL_PAGE_STEP = 'rentenbankFinalPage';
export const PEOPLE_DETAILS = 'peopleDetails';
export const CONTRACT_DETAILS = 'contractDetails';
export const CUSTOMER_DATA = 'customerData';
export const LEASEPLAN_FINAL_PAGE = 'leaseplanFinalPage';
export const CONTACT_PERSON_DETAILS = 'contactPerson';
export const BENEFICIARY_OWNER_DETAILS = 'beneficiaryOwnerDetails';
export const LEGAL_REPRESENTATIVE_DETAILS = 'legalRepresentativeDetails';

/*
 * PurposeSection kind
 */

export const FINANCING_AMOUNT = 'amount';
export const CORONA_FINANCING_AMOUNT = 'corona-amount';
export const CORONA_OTHER_KFW_FINANCING_AMOUNT = 'corona-other-kfw-amount';

export const PURPOSE_KIND = 'purpose-kind';
export const PURPOSE_KIND_BFS_SERVICE = 'purpose-kind-bfs-service';
export const PURPOSE_KIND__REAL_ESTATE = 'real_estate';
export const PURPOSE_KIND__OFFICE_EQUIPMENT = 'office_equipment';
export const PURPOSE_KIND__GOODS = 'goods';
export const PURPOSE_KIND__VEHICLE = 'vehicle';
export const PURPOSE_KIND__DEBT_RESCHEDULING = 'debt_rescheduling';
export const PURPOSE_KIND__MACHINE = 'machine';
export const PURPOSE_KIND__OTHER = 'other';
export const PURPOSE_KIND__OVERDRAFT_LIQUIDITY = 'liquidity';
export const PURPOSE_KIND__GUARANTY = 'guaranty';
export const PURPOSE_KIND__CORONA = 'corona';
export const PURPOSE_KIND__PROJECT_FINANCING = 'project_financing';
export const PURPOSE_KIND__KFINANZ = 'kfinanz';
export const PURPOSE_KIND__CREFO_FACTORING = 'crefo_factoring';
export const PURPOSE_KIND__BUILDING = 'property';
export const PURPOSE_KIND__ENERGY_EFFICIENCY = 'energy_efficiency';
export const PURPOSE_KIND__ONLINE_FACTORING = 'online_factoring';
export const PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING = 'bfs_service_online_factoring';
export const PURPOSE_KIND__FACTOMAT = 'factomat';

/*
 * User profile industries
 */

export const INDUSTRY__BUILDING = 'building';
export const INDUSTRY__MINING = 'mining';
export const INDUSTRY__ENERGY_AND_WATER_SUPPLY = 'energy_and_water_supply';
export const INDUSTRY__SERVICE = 'service';
export const INDUSTRY__FINANCE_AND_INSURANCE = 'finance_and_insurance';
export const INDUSTRY__HOSPITALITY = 'hospitality';
export const INDUSTRY__HEALTH_AND_SOCIAL = 'health_and_social';
export const INDUSTRY__TRADE = 'trade';
export const INDUSTRY__CRAFT = 'craft';
export const INDUSTRY__REAL_ESTATE = 'real_estate';
export const INDUSTRY__COMMUNICATION = 'communication';
export const INDUSTRY__AGRICULTURE = 'agriculture';
export const INDUSTRY__MANUFACTURING = 'manufacturing';
export const INDUSTRY__PROCESSING = 'processing';
export const INDUSTRY__TRANSPORT = 'transport';
export const INDUSTRY__OTHER = 'other';

export const INDUSTRY__MAINTENANCE = 'maintenance';
export const INDUSTRY__SOCIAL = 'social';
export const INDUSTRY__HEALTH = 'health';
export const INDUSTRY__RESIDE = 'reside';
export const INDUSTRY__FUNDRAISER = 'fundraiser';
export const INDUSTRY__FOUNDATION = 'foundation';
export const INDUSTRY__RELIGIOUS = 'religious';
export const INDUSTRY__UPBRINGING = 'upbringing';

/*
 * Project financing
 */

export const PROJECT_FINANCING_ROLE = 'project-financing-role';
export const ROLES__FILE_HALTER = 'stockholder';
export const ROLES__PROPERTY_DEVELOPER = 'developer';
export const ROLES__PARTITION = 'partition';
export const ROLES__PROPERTY_BUYER = 'land_buyer';

export const PROJECT_FINANCING_INVESTMENT_AMOUNT = 'project-financing-investment-amount';
export const PROJECT_FINANCING_OWN_FUNDS = 'project-financing-own-funds';
export const PROJECT_FINANCING_MEZZANINE = 'project-financing-mezzanine';
export const PROJECT_FINANCING_SUBORDINATED_CAPITAL = 'project-financing-subordinated-capital';
export const PROJECT_FINANCING_OWN_WORK = 'project-financing-own-work';
export const PROJECT_FINANCING_OBJECT_ADDRESS = 'project-financing-object-address';
export const PROJECT_FINANCING_OBJECT_ZIP_CODE = 'project-financing-object-zip-code';
export const PROJECT_FINANCING_OBJECT_CITY = 'project-financing-object-city';
export const PROJECT_FINANCING_OBJECT_DESCRIPTION = 'project-financing-object-description';
export const PROJECT_FINANCING_QUALITY_FURNISHING = 'project-financing-quality-furnishing';
export const PROJECT_FINANCING_QUALITY_CONDITION = 'project-financing-quality-condition';
export const PROJECT_FINANCING_YEAR_OF_MODERNIZATION = 'project-financing-year-of-modernization';
export const PROJECT_FINANCING_YEAR_OF_CONSTRUCTIONS = 'project-financing-year-of-construction';
export const PROJECT_FINANCING_BALCONY = 'project-financing-balcony';
export const PROJECT_FINANCING_ADDITIONAL_BATHROOM = 'project-financing-additional-bathroom';
export const PROJECT_FINANCING_LOT_SIZE = 'project-financing-lot-size';
export const PROJECT_FINANCING__BUILDING_YEAR = 'project-financing-year-of-building';
export const PROJECT_FINANCING__MODERNIZATION_YEAR = 'project-financing-year-of-modernization';
export const PROJECT_FINANCING__FURNISHING_QUALITY = 'project-financing-furnishing-quality';
export const PROJECT_FINANCING__GUEST_BATHROOM = 'project-financing-additional-bathroom';
export const PROJECT_FINANCING__BALKONY = 'project-financing-balkony';
export const PROJECT_FINANCING__LIFT = 'project-financing-lift';
export const PROJECT_FINANCING__CELLAR = 'project-financing-cellar';
export const PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS = 'project-financing-parking-lots-count';
export const PROJECT_FINANCING__PLANNED_SALES_PRICE_PER_UNIT = 'planned-sales-price';
export const PROJECT_FINANCING__PARKING_LOTS_SALES_PRICE_PER_UNIT =
  'project-financing-parking-lots-planned-sales-price';
export const PROJECT_FINANCING__PARKING_LOTS_RENT_NOW = 'project-financing-parking-lots-rent-now';
export const PROJECT_FINANCING__PARKING_LOTS_RENT_FUTURE =
  'project-financing-parking-lots-rent-future';
export const PROJECT_FINANCING__NUMBER_OF_GARAGES = 'project-financing-garages-count';
export const PROJECT_FINANCING__GARAGES_SALES_PRICE_PER_UNIT =
  'project-financing-garages-planned-sales-price';
export const PROJECT_FINANCING__GARAGES_RENT_NOW = 'project-financing-garages-rent-now';
export const PROJECT_FINANCING__GARAGES_RENT_FUTURE = 'project-financing-garages-rent-future';
export const PROJECT_FINANCING_IS_DEVIATING_FROM_FUTURE_USAGE = 'project-financing-usage-deviation';
export const PROJECT_FINANCING__NUMBER_OF_UNITS = 'number-of-units';
export const PROJECT_FINANCING_FURNISHING_QUALITY__SIMPLE = 'simple';
export const PROJECT_FINANCING_FURNISHING_QUALITY__NORMAL = 'normal';
export const PROJECT_FINANCING_FURNISHING_QUALITY__GOOD = 'good';
export const PROJECT_FINANCING_FURNISHING_QUALITY__VERY_GOOD = 'very_good';
export const PROJECT_FINANCING_FURNISHING_QUALITY__HIGH_QUALITY = 'high_quality';
export const PROJECT_FINANCING__QUALITY_CONDITION = 'project-financing-quality-condition';
export const PROJECT_FINANCING__QUALITY_CONDITION__SIMPLE = 'simple';
export const PROJECT_FINANCING__QUALITY_CONDITION__MEDIUM = 'medium';
export const PROJECT_FINANCING__QUALITY_CONDITION__GOOD = 'good';
export const PROJECT_FINANCING__OBJECT_USAGE = 'project-financing-object-usages';
export const PROJECT_FINANCING__OBJECT_FUTURE_USAGE = 'project-financing-object-usages-future';
export const PROJECT_FINANCING_FUTURE_USAGE_TYPE = 'project-financing-future-usage-type';
export const PROJECT_FINANCING_CURRENT_USAGE_TYPE = 'project-financing-current-usage-type';
export const PROJECT_FINANCING_PROPERTY_DOCUMENT = 'project-financing-property-document';
export const PROJECT_FINANCING_ADDITIONAL_DETAILS = 'project-financing-additional-details';
export const PROJECT_FINANCING_FILE_HALTER_PARTITION_FIELDS =
  'project-financing-file-halter-partition-fields';
export const PROJECT_FINANCING_OBJECT_FUTURE_USAGE_FORM =
  'project-financing-object-future-usage-form';

export const PROJECT_FINANCING_TYPE_OF_USAGE = 'type-of-use';
export const PROJECT_FINANCING_USAGE_KIND_TYPE = 'kind-of-usage';
export const PROJECT_FINANCING_USAGE_KIND_TYPE_CURRENT = 'current';
export const PROJECT_FINANCING_USAGE_KIND_TYPE_FUTURE = 'future';
export const PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT = 'type-of-use-current';
export const PROJECT_FINANCING_TYPE_OF_USAGE_FUTURE = 'type-of-use-future';
export const PROJECT_FINANCING_TYPE_OF_USAGE__HOUSING_INDUSTRY = 'residential';
export const PROJECT_FINANCING_TYPE_OF_USAGE__COMMERCIAL = 'commercial_office';
export const PROJECT_FINANCING_TYPE_OF_USAGE__NURSING = 'care_social';
export const PROJECT_FINANCING_TYPE_OF_USAGE__PARKING = 'retail_parking_garage';
export const PROJECT_FINANCING_TYPE_OF_USAGE__OTHER = 'other';
export const PROJECT_FINANCING_TYPE_OF_USAGE__MIXED = 'mixed';
export const PROJECT_FINANCING_MARKET_TRIGGER_CONDITION = 'market-trigger-condition';
export const PROFITABILITY_USUAL_SALES_COST_RATE = 'usual-cost-rate';

export const PROJECT_FINANCING_TYPE_OF_USAGE_DETAILS = 'type-of-use-description';
export const PROJECT_FINANCING_USAGE_SPACE = 'usage-space';
export const PROJECT_FINANCING_PERCENTAGE_OF_RENT = 'percentage-of-rent';
export const PROJECT_FINANCING_RENT_NOW = 'rent-now';
export const PROJECT_FINANCING_RENT_FUTURE = 'rent-future';
export const PROJECT_FINANCING_LIST_OF_RENTERS = 'project-financing-list-of-renters';
export const PROJECT_FINANCING_KNOWS_ANNUAL_RENT = 'project-financing-approx-rent-amount-known';
export const PROJECT_FINANCING_ANNUAL_RENT_AMOUNT = 'project-financing-approx-rent-amount';
export const PROJECT_FINANCING_PLAN_TO_INCREASE_ANNUAL_RENT =
  'project-financing-rent-increase-planned';
export const PROJECT_FINANCING_DESCRIPTION_OF_THE_MEASURE =
  'project-financing-approx-rent-description-of-measure';

export const PROJECT_FINANCING_ANCHOR_TENANT = 'project-financing-anchor-tenant';
export const PROJECT_FINANCING_RENTAL_CONTRACTS = 'project-financing-rental-contracts';
export const PROJECT_FINANCING_RENOVATION_PLANNED = 'project-financing-renovation-planned';
export const PROJECT_FINANCING_RENOVATION_DESCRIPTION = 'project-financing-renovation-description';
export const PROJECT_FINANCING_DOCUMENTS_ARE_AVAILABLE =
  'project-financing-planning-documents-available';
export const PROJECT_FINANCING_LIST_OF_CONTAMINATED_SITES_EXIST =
  'project-financing-contaminated-sites-register';
export const PROJECT_FINANCING_HAS_BUILD_PERMIT = 'project-financing-received-building-permission';
export const PROJECT_FINANCING__HAS_LIST_OF_BUILDING_ENCUMBRANCES =
  'project-financing-building-encumbrances-list';
export const PROJECT_FINANCING__HAS_ENERGY_CERTIFICATE = 'project-financing-energy-certificate';
export const CONTROL_OBJECT_DATA_STEP = 'control-object-data-step';
export const FUTURE_USAGE_STEP = 'future-usage-step';
export const CURRENT_USAGE_STEP = 'current-usage-step';
export const PROJECT_USAGE_SPACE_SUM_NOT_RESIDENTIAL = 'project-usage-space-sum-not-residential';
export const PROJECT_USAGE_SPACE_SUM_RESIDENTIAL = 'project-usage-space-sum-residential';
export const MITTWEIDA_CONTROL_OBJECT_DATA_STEP = 'mittweida-control-object-data-step';
export const MITTWEIDA_FUTURE_USAGE_STEP = 'mittweida-future-usage-step';
export const MITTWEIDA_CURRENT_USAGE_STEP = 'mittweida-current-usage-step';
/*
 * Real estate
 */

export const REAL_ESTATE_PROJECT = 'real-estate-project';
export const REAL_ESTATE_PROJECT__NEW_BUILDING = 'new_building';
export const REAL_ESTATE_PROJECT__PURCHASE = 'purchase';
export const REAL_ESTATE_PROJECT__FOLLOW_UP = 'follow_up';
export const REAL_ESTATE_PROJECT__RECONSTRUCTION = 'reconstruction';
export const REAL_ESTATE_PROJECT__DEVELOPMENT_PROJECT = 'development_project';

export const REAL_ESTATE_KIND = 'real-estate-kind';
export const REAL_ESTATE_KIND__OFFICE = 'office_building';
export const REAL_ESTATE_KIND__INDUSTRIAL = 'warehouse_or_production_hall';
export const REAL_ESTATE_KIND__RESIDENCE = 'residence';
export const REAL_ESTATE_KIND__FAMILY_HOUSE = 'single_or_multi_family_house';
export const REAL_ESTATE_KIND__PROPERTY_DEVELOPMENT = 'property_development';
export const REAL_ESTATE_KIND__BUSINESS = 'business_building';
export const REAL_ESTATE_KIND__SHOPPING_CENTER = 'specialty_shopping_center';
export const REAL_ESTATE_KIND__HOTEL = 'hotel';
export const REAL_ESTATE_KIND__LAND_ONLY = 'only_land';
export const REAL_ESTATE_KIND__OTHER = 'other';

export const REAL_ESTATE_SCHEDULED_DATE = 'real-estate-scheduled-date';
export const REAL_ESTATE_RESIDUAL_PERCENTAGE = 'real-estate-residual-percentage';

export const REAL_ESTATE_LAND_SIZE = 'real-estate-land-size';
export const REAL_ESTATE_CONSTRUCTION_DATE = 'real-estate-construction-date';
export const REAL_ESTATE_BUILDING_SIZE = 'real-estate-building-size';
export const REAL_ESTATE_HERITABLE_BUILDING_RIGHT = 'real-estate-heritable-building-right';
export const REAL_ESTATE_HERITABLE_BUILDING_RIGHT_OBLIGATION =
  'real-estate-heritable-building-right-obligation';
export const REAL_ESTATE_RENTED_OUT_AREA = 'real-estate-rented-out-area';
export const REAL_ESTATE_COLD_RENT = 'real-estate-cold-rent';
export const REAL_ESTATE_ZIP_CODE = 'real-estate-zip-code';

export const REAL_ESTATE_USAGE_KIND = 'real-estate-usage-kind';
export const REAL_ESTATE_USAGE_KIND__OWN_USE = 'own_use';
export const REAL_ESTATE_USAGE_KIND__THIRD_PARTY = 'third_party_rental';
export const REAL_ESTATE_USAGE_KIND__BOTH = 'both';
export const REAL_ESTATE_USAGE_KIND__OTHER = 'other';

export const REAL_ESTATE_TAXATION = 'real-estate-taxation';
export const REAL_ESTATE_TAXATION__NET = 'net';
export const REAL_ESTATE_TAXATION__GROSS = 'gross';

export const REAL_ESTATE_PURCHASE_PRICE = 'real-estate-purchase-price';

export const REAL_ESTATE_FOLLOW_UP_AMOUNT = 'real-estate-follow-up-amount';

export const REAL_ESTATE_RECONSTRUCTION_COST = 'real-estate-reconstruction-costs';

export const REAL_ESTATE_PROJECT_DESCRIPTION = 'real-estate-project-description';

export const REAL_ESTATE_PROJECT_DEVELOPMENT_AMOUNT = 'real-estate-development-project-amount';
export const REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION =
  'real-estate-development-project-description';

export const REAL_ESTATE_FINANCING_OBJECT_DESCRIPTION = 'real-estate-financing-object-description';
export const REAL_ESTATE_TOTAL_COST = 'real-estate-total-cost';
export const REAL_ESTATE_COST_OF_MODERNIZATION = 'real-estate-cost-of-modernization';
export const REAL_ESTATE_PLANNED_START_OF_CONSTRUCTION =
  'real-estate-planned-start-of-construction';

/*
 * Goods
 */

export const GOODS__PURCHASE_PRICE = 'goods-purchase-price';
export const GOODS__DESCRIPTION = 'goods-description';
export const GOODS__SUPPLIER = 'goods-supplier';
export const GOODS__ESTIMATED_DELIVERY_DATE = 'goods-estimated-delivery-date';

/*
 * Vehicle
 */

export const VEHICLE_PURCHASE_PRICE = 'vehicle-price';
export const VEHICLE_COUNT = 'vehicle-count';
export const VEHICLE_DESCRIPTION = 'vehicle-description';
export const VEHICLE_NEW = 'vehicle-new';
export const VEHICLE_YEAR = 'vehicle-year';

export const VEHICLE_KIND = 'vehicle-kind';
export const VEHICLE_KIND__CAR = 'car';
export const VEHICLE_KIND__UTILITY = 'utility_vehicle';
export const VEHICLE_KIND__TRAILER = 'trailer';
export const VEHICLE_KIND__MOTORCYCLE = 'motorcycle';
export const VEHICLE_KIND__OTHER = 'other';

export const VEHICLE_OTHER_DESCRIPTION = 'vehicle-other-description';
export const VEHICLE_MANUFACTURER = 'vehicle-manufacturer';
export const VEHICLE_MODEL = 'vehicle-model';
export const VEHICLE_YEARLY_MILEAGE = 'vehicle-yearly-mileage';

/*
 * Debt rescheduling
 */

export const DEBT_RESCHEDULING_DESCRIPTION = 'debt-rescheduling-description';

/*
 * Liquidity
 */

export const LIQUIDITY_DESCRIPTION = 'liquidity-description';

/*
 * Machines
 */

export const MACHINE_CATEGORY = 'machine-category';
export const MACHINE_CATEGORY__METAL = 'metal';
export const MACHINE_CATEGORY__WOOD = 'wood';
export const MACHINE_CATEGORY__PLASTIC = 'plastic';
export const MACHINE_CATEGORY__TOOL = 'tool';
export const MACHINE_CATEGORY__PRINT = 'print';
export const MACHINE_CATEGORY__CONSTRUCTION = 'construction';
export const MACHINE_CATEGORY__HEALTH = 'health';
export const MACHINE_CATEGORY__ENERGY = 'energy';
export const MACHINE_CATEGORY__RENEWABLE_ENERGY = 'renewable_energy';
export const MACHINE_CATEGORY__FOOD = 'food';
export const MACHINE_CATEGORY__LOGISTIC = 'logistic';
export const MACHINE_CATEGORY__PACKAGING = 'packaging';
export const MACHINE_CATEGORY__RECYCLING = 'recycling';
export const MACHINE_CATEGORY__AGRICULTURE = 'agriculture';
export const MACHINE_CATEGORY__HARD_SOFTWARE = 'hard_and_software';
export const MACHINE_CATEGORY__TELECOM = 'telecommunication';
export const MACHINE_CATEGORY__OTHER = 'other';

export const MACHINE_PRICE = 'machine-price';
export const MACHINE_PERIOD_OF_USE = 'machine-period-of-use';
export const MACHINE_KIND = 'machine-kind';
export const MACHINE_MANUFACTURER = 'machine-manufacturer';
export const MACHINE_NEW = 'machine-is-new';
export const MACHINE_AGE = 'machine-age';
export const MACHINE_ORIGINAL_PRICE = 'machine-original-price';
export const MACHINE_IS_ORDERED = 'machine-is-ordered';
export const MACHINE_DELIVERY_DATE = 'machine-delivery-date';
export const MACHINE_COUNT = 'machine-count';
export const MACHINE_DESCRIPTION = 'machine-description';

/*
 * Office Equipment
 */

export const OFFICE_EQUIPMENT_PRICE = 'office-equipment-purchase-price';
export const OFFICE_EQUIPMENT_DESCRIPTION = 'office-equipment-description';

/*
 * Guaranty
 */

export const GUARANTY_GUARANTY_REQUIREMENT = 'amount';
export const GUARANTY_RENTAL_DEPOSIT = 'guaranty-rental-deposit';
export const GUARANTY_RENTAL_DESPOSIT = 'guaranty-rental-desposit';

export const GUARANTY_PARTIAL_RETIREMENT_ACCOUNTS = 'guaranty-partial-retirement-accounts';
export const GUARANTY_PERFORMANCE_OF_CONTRACT = 'guaranty-performance-of-contract';
export const GUARANTY_DEPOSIT_AND_ADVANCE_PAYMENTS = 'guaranty-deposit-and-advance-payments';
export const GUARANTY_WARRANTY = 'guaranty-warranty';
export const GUARANTY_LITIGATION_BOND = 'guaranty-litigation-bond';
export const GUARANTY_OTHER_TYPE = 'guaranty-other-type';
export const GUARANTY_TYPE = 'guaranty-type';
export const GUARANTY_DESCRIPTION = 'guaranty-description';

/*
 * Other
 */

export const PARTNER_LOGO = 'partner-logo';

export const OTHER_PURPOSE_DESCRIPTION = 'other-description';
export const OTHER_PRODUCT_DESCRIPTION = 'other-product-description';

export const BUILDING_PROJECT = 'property-project';
export const BUILDING_PROJECT__NEW = 'new_building';
export const BUILDING_PROJECT__PURCHASE = 'purchase';
export const BUILDING_PROJECT__RESCHEDULE = 'follow_up';
export const BUILDING_PROJECT__RECONSTRUCTION = 'reconstruction';

export const BUILDING_TYPE = 'property-kind';
export const BUILDING_TYPE__DETACHED = 'single_family_house';
export const BUILDING_TYPE__DUPLEX = 'two_family_house';
export const BUILDING_TYPE__CONDOMINIUM = 'condominium';
export const BUILDING_TYPE__APARTMENT = 'multi_family_house';
export const BUILDING_TYPE__RESIDENTIAL_AND_COMMERCIAL = 'residential_and_commercial_building';
export const BUILDING_TYPE__OFFICE = 'office_building';
export const BUILDING_TYPE__PRODUCTION_HALL = 'production_hall';
export const BUILDING_TYPE__STORAGE_HALL = 'warehouse';
export const BUILDING_TYPE__DEVELOPER_MEASURE = 'property_development';
export const BUILDING_TYPE__COMMERCIAL = 'business_building';
export const BUILDING_TYPE__RETAIL_PARK = 'specialty_shopping_center';
export const BUILDING_TYPE__HOTEL = 'hotel';
export const BUILDING_TYPE__OTHER = 'other';

export const BUILDING_TYPE_DESCRIPTION = 'property-kind-description';
export const BUILDING_COOWNERSHIP = 'property-co-ownership-share';
export const BUILDING_CONDOMINIUM_NUM = 'property-condominium-nr';
export const BUILDING_NUMBER_OF_RESIDENTIAL_UNITS = 'property-number-of-residential-units';
export const BUILDING_TOTAL_USABLE_SPACE = 'property-total-living-and-usable-space';
export const BUILDING_LIVING_SPACE = 'property-living-space';
export const BUILDING_COMMERCIAL_SPACE = 'property-commercial-space';
export const BUILDING_SHARE_OF_COMMERCIAL_SPACE = 'property-share-of-commercial-space';

export const BUILDING_TYPE_OF_USE = 'property-type-of-use';
export const BUILDING_TYPE_OF_USE__PERSONAL_USE = 'own_use';
export const BUILDING_TYPE_OF_USE__THIRD_PARTY_RENTAL = 'third_party_rental';
export const BUILDING_TYPE_OF_USE__OWN_USE_LETTING_THIRD_PARTY = 'both';

export const BUILDING_COLD_RENT_PER_YEAR_COMMERCIAL = 'property-yearly-cold-rent-commercial';
export const BUILDING_RENTED_SPACE_COMMERCIAL = 'property-rented-space-commercial';
export const BUILDING_COLD_RENT_PER_YEAR_LIVING = 'property-yearly-cold-rent-residential';
export const BUILDING_RENTED_SPACE_LIVING = 'property-rented-space-residential';
export const BUILDING_CONSTRUCTION_YEAR = 'property-construction-date';
export const BUILDING_LATEST_SUBSTENCIAL_MODERNISATION =
  'property-last-substantial-modernization-date';
export const BUILDING_STREET_AND_HOUSE_NUMBER = 'property-street-and-number';
export const BUILDING_POSTAL_CODE = 'property-zip-code';
export const BUILDING_PLACE = 'property-city';

export const BUILDING_PURCHASE_PRICE = 'property-purchase-price';
export const BUILDING_PLOT_PRICE = 'property-price-of-land';
export const BUILDING_ADDITIONAL_COSTS = 'property-additional-costs';
export const BUILDING_CONSTRUCTION_MANUFACTURE_COST = 'property-construction-costs';
export const BUILDING_MODERNISATION_COSTS = 'property-modernization-costs';
export const BUILDING_DEBT_REDEMPTION = 'property-debt-redemption';
export const BUILDING_USE_OF_FUNDS_SUM = 'property-use-of-funds-sum';

export const BUILDING_EQUITY = 'property-equity';
export const BUILDING_PERSONAL_CONTRIBUTION = 'property-personal-contribution';
export const BUILDING_PROPERTY = 'property-building-site-cost';
export const BUILDING_BORROWED_CAPITAL = 'property-borrowed-capital'; // sent as financing amount
export const BUILDING_DEBT_CAPITAL_OTHER = 'property-borrowed-capital-other';
export const BUILDING_SOURCE_OF_FUNDS_SUM = 'property-source-of-funds-sum';

/*
 * Roles
 */

/*
 * Product kind
 */

export const PRODUCT_KIND = 'product-kind';
export const PRODUCT_KIND__LOAN = 'loan';
export const PRODUCT_KIND__PROPERTY_LOAN = 'property_loan';
export const PRODUCT_KIND__LEASING = 'leasing';
export const PRODUCT_KIND__OVERDRAFT = 'overdraft';
export const PRODUCT_KIND__FACTORING = 'factoring';
export const PRODUCT_KIND__CREFO_FACTORING = 'crefo_factoring';
export const PRODUCT_KIND__KFINANZ = 'kfinanz';
export const PRODUCT_KIND__OTHER = 'other';
export const PRODUCT_KIND__AVAL = 'aval';
export const PRODUCT_KIND__LIQUIDITY = 'liquidity';
export const PRODUCT_KIND__MEZZANINE = 'mezzanine';
export const PRODUCT_KIND__PROJECT_FINANCING = 'project_financing';
export const PRODUCT_KIND__INVESTMENT_LOAN = 'investment_loan';
export const PRODUCT_KIND__HIRE_PURCHASE = 'hire_purchase';
export const PRODUCT_KIND__SURETY = 'surety';
export const PRODUCT_KIND__BFSS = 'PRODUCT_KIND__BFSS';
export const PRODUCT_KIND__FACTOMAT = 'factomat';
export const PRODUCT_KIND__BFSS_ONLINE_FACTORING = 'bfs_service_online_factoring';

/*
 * Loan
 */

export const LOAN_ADVANCE_PAYMENT = 'loan-advance-payment';
export const LOAN_TYPE = 'loan-kind';
export const LOAN_TYPE__AMORTISABLE = 'amortisable_loan';
export const LOAN_TYPE__ANNUITY = 'annuity_loan';
export const LOAN_TYPE__PAYABLE_AT_TERM = 'loan_payable_at_term';

export const LOAN_TERM = 'loan-term-in-months';
export const LOAN_TERM_IN_MONTHS = 'loan-term-in-months';

export const LOAN_FIXED_INTEREST_RATE = 'loan-fixed-interest-rate';
export const LOAN_FIXED_INTEREST_RATE__FIXED = 'true';
export const LOAN_FIXED_INTEREST_RATE__VARIABLE = 'false';

export const LOAN_INTEREST_PERIOD_IN_MONTHS = 'loan-interest-period-in-months';

export const LOAN_CONSIDER_SUBSIDIES = 'consider-subsidies';

export const LOAN_DESCRIPTION = 'loan-description';

export const PROPERTY_LOAN_KIND = 'property-loan-kind';
export const PROPERTY_LOAN_KIND__CLASSIC = 'annuity_loan';
export const PROPERTY_LOAN_KIND__ADVANCED = 'advance_loan';
export const PROPERTY_LOAN_KIND__INTERIM = 'interim_loan';

export const PROPERTY_LOAN_TERM_IN_MONTHS = 'property-loan-term-in-months';
export const PROPERTY_LOAN_DESCRIPTION = 'property-loan-description';

/*
 *  Factoring
 */

export const FACTORING_TURNOVER_CLASS = 'factoring-turnover-class';
export const FACTORING_TURNOVER_CLASS__0_TO_10_THOUSAND = '0_to_10_thousand';
export const FACTORING_TURNOVER_CLASS__10_TO_20_THOUSAND = '10_to_20_thousand';
export const FACTORING_TURNOVER_CLASS__20_TO_30_THOUSAND = '20_to_30_thousand';
export const FACTORING_TURNOVER_CLASS__30_TO_40_THOUSAND = '30_to_40_thousand';
export const FACTORING_TURNOVER_CLASS__40_TO_50_THOUSAND = '40_to_50_thousand';
export const FACTORING_TURNOVER_CLASS__50_TO_75_THOUSAND = '50_to_75_thousand';
export const FACTORING_TURNOVER_CLASS__75_TO_100_THOUSAND = '75_to_100_thousand';
export const FACTORING_TURNOVER_CLASS__100_TO_250_THOUSAND = '100_to_250_thousand, ';
export const FACTORING_TURNOVER_CLASS__250_TO_500_THOUSAND = '250_to_500_thousand';
export const FACTORING_TURNOVER_CLASS__500_THOUSAND_TO_1_MILLIOND = '500_thousand_to_1_million';
export const FACTORING_TURNOVER_CLASS__1_MILLION_TO_2_5_MILLION = '1_million_to_2_5_million';
export const FACTORING_TURNOVER_CLASS__2_5_TO_5_MILLION = '2_5_to_5_million';
export const FACTORING_TURNOVER_CLASS__5_TO_10_MILLION = '5_to_10_million';
export const FACTORING_TURNOVER_CLASS__10_TO_25_MILLION = '10_to_25_million';
export const FACTORING_TURNOVER_CLASS__25_TO_50_MILLION = '25_to_50_million';
export const FACTORING_TURNOVER_CLASS__50_TO_100_MILLION = '50_to_100_million';
export const FACTORING_TURNOVER_CLASS__MORE_THAN_100_MILLION = 'more_than_100_million';

export const FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS =
  'factoring-annual-net-profit-or-loss-class';
export const FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__ZERO = 'zero';
export const FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__1_TO_10_THOUSAND = '1_to_10_thousand';
export const FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__10_TO_20_THOUSAND = '10_to_20_thousand';
export const FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__20_TO_30_THOUSAND = '20_to_30_thousand';
export const FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__30_TO_40_THOUSAND = '30_to_40_thousand';
export const FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__40_TO_50_THOUSAND = '40_to_50_thousand';
export const FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__50_TO_75_THOUSAND = '50_to_75_thousand';
export const FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__75_TO_100_THOUSAND = '75_to_100_thousand';
export const FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__100_TO_250_THOUSAND = '100_to_250_thousand';
export const FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__250_TO_500_THOUSAND = '250_to_500_thousand';
export const FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__500_TO_750_THOUSAND = '500_to_750_thousand';
export const FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__LOSS = 'loss';

export const FACTORING_FOREIGN_SHARE = 'factoring-foreign-share';

export const FACTORING_TURNOVER_SHARE_WITH_LARGEST_CUSTOMER =
  'factoring-turnover-share-with-largest-customer';

export const FACTORING_AVERAGE_PERIOD_OF_PAYMENT = 'factoring-average-period-of-payment';
export const FACTORING_AVERAGE_PERIOD_OF_PAYMENT__UP_TO_14_DAYS = 'up_to_14_days';
export const FACTORING_AVERAGE_PERIOD_OF_PAYMENT__15_TO_30_DAYS = '15_to_30_days';
export const FACTORING_AVERAGE_PERIOD_OF_PAYMENT__31_TO_45_DAYS = '31_to_45_days';
export const FACTORING_AVERAGE_PERIOD_OF_PAYMENT__46_TO_60_DAYS = '46_to_60_days';
export const FACTORING_AVERAGE_PERIOD_OF_PAYMENT__MORE_THAN_60_DAYS = 'more_than_60_days';

export const FACTORING_COMMERCIAL_CREDIT_INSURANCE = 'factoring-commercial-credit-insurance';

export const FACTORING_ACTIVE_CUSTOMERS = 'factoring-active-customers';

export const FACTORING_BILLS_RANGE = 'factoring-bills-range';
export const FACTORING_BILLS_RANGE_UP_TO_250 = 'up_to_250';
export const FACTORING_BILLS_RANGE_251_TO_500 = '251_to_500';
export const FACTORING_BILLS_RANGE_501_TO_1000 = '501_to_1000';
export const FACTORING_BILLS_RANGE_1001_TO_2500 = '1001_to_2500';
export const FACTORING_BILLS_RANGE_MORE_THAN_2500 = 'more_than_2500';

export const FACTORING_ALREADY_IN_USE = 'factoring-already-in-use';

export const FACTORING_ALREADY_ON_HAND_AT_FACTORING_COMPANY =
  'factoring-already-on-hand-at-factoring-company';

export const FACTORING_ADDITIONAL_INFORMATION_FOR_COMPEON =
  'factoring-additional-information-for-compeon';

export const FACTORING_ADDITIONAL_INFORMATION_FOR_BANKS =
  'factoring-additional-information-for-banks';

/*
 * Overdraft
 */

export const OVERDRAFT_TERMINABILITY = 'overdraft-terminability';

export const OVERDRAFT_END_AT = 'overdraft-end-at';

export const OVERDRAFT_INTERESTED_IN_ALTERNATIVE_FINANCING =
  'overdraft-interested-in-alternative-financing';

/*
 * Mezzanine
 */

export const MEZZANINE_DESCRIPTION = 'mezzanine-description';

/*
 * Project financing
 */

export const PROJECT_FINANCING_INTERESTED_IN_ALTERNATIVE_FINANCING =
  'project-financing-interested-in-alternative-financing';

/*
 * Investment loan
 */

export const INVESTMENT_LOAN_ADVANCE_PAYMENT = 'investment-loan-advance-payment';

export const INVESTMENT_LOAN_TERM_IN_MONTHS = 'investment-loan-term-in-months';

export const INVESTMENT_LOAN_TERM_IN_YEARS = 'investment-loan-term-in-years';

export const INVESTMENT_LOAN_AMORTISATION_KIND = 'investment-loan-amortisation-kind';
export const INVESTMENT_LOAN_AMORTISATION_KIND__FULL_AMORTISATION = 'full_amortisation';
export const INVESTMENT_LOAN_AMORTISATION_KIND__PARTIAL_AMORTISATION = 'partial_amortisation';

export const INVESTMENT_LOAN_RESIDUAL_VALUE = 'investment-loan-residual-value';

export const INVESTMENT_LOAN_DESCRIPTION = 'investment-loan-description';

export const INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS =
  'investment-loan-interested-in-other-products';

/*
 * Hire purchase
 */

export const HIRE_PURCHASE_ADVANCE_PAYMENT = 'hire-purchase-advance-payment';

export const HIRE_PURCHASE_TERM_IN_MONTHS = 'hire-purchase-term-in-months';

export const HIRE_PURCHASE_AMORTISATION_KIND = 'hire-purchase-amortisation-kind';
export const HIRE_PURCHASE_AMORTISATION_KIND__FULL_AMORTISATION = 'full_amortisation';
export const HIRE_PURCHASE_AMORTISATION_KIND__PARTIAL_AMORTISATION = 'partial_amortisation';

export const HIRE_PURCHASE_RESIDUAL_VALUE = 'hire-purchase-residual-value';

export const HIRE_PURCHASE_DESCRIPTION = 'hire-purchase-description';

export const HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN =
  'hire-purchase-interested-in-investment-loan';

/*
 * Interested in insurance
 */

export const VEHICLE_INTERESTED_IN_INSURANCE = 'vehicle-interested-in-insurance';

export const MACHINE_INTERESTED_IN_INSURANCE = 'machine-interested-in-insurance';

export const OFFICE_EQUIPMENT_INTERESTED_IN_INSURANCE = 'office-equipment-interested-in-insurance';

export const INTERESTED_IN_INSURANCE = 'interested-in-insurance';

/*
 * Securities
 */

export const SECURITIES = 'securities';
export const SECURITIES_VALUE_ESTIMATION = 'value';
export const SECURITIES_DESCRIPTION = 'description';

export const SECURITIES_TYPE = 'kind';
export const SECURITIES_TYPE__FINANCING_OBJECT = 'financing_object';
export const SECURITIES_TYPE__GUARANTEE = 'guarantee';
export const SECURITIES_TYPE__MORTGAGE = 'land_charges';
export const SECURITIES_TYPE__LIFE_INSURANCE_CESSION = 'cession_of_life_insurance';
export const SECURITIES_TYPE__BUILDING_LOAN_CESSION = 'building_loan_agreement';
export const SECURITIES_TYPE__CREDIT_BALANCES_CESSION = 'cession_of_credit_balances';
export const SECURITIES_TYPE__SECURITIES_TRANSFER = 'warehouse_transfer_by_way_of_security';
export const SECURITIES_TYPE__RECEIVABLES_ASSIGNMENT = 'cession_of_receivables';
export const SECURITIES_TYPE__FINANCING_OBJECT_MORTGAGE = 'land_charges_on_financing_object';

export const FAVORED_DECISION_DATE = 'favored-decision-date';
export const EXCLUDED_BANKS = 'excluded-bank-codes';

/*
 * Company details
 */

export const GENDER_MAN = 'mr';
export const GENDER_WOMAN = 'ms';

export const COMPANY_DETAILS_GENDER = 'company-details-gender';
export const COMPANY_DETAILS_GENDER__MAN = 'man';
export const COMPANY_DETAILS_GENDER__WOMAN = 'woman';

export const COMPANY_DETAILS_FIRST_NAME = 'company-details-first-name';

export const COMPANY_DETAILS_LAST_NAME = 'company-details-last-name';

export const COMPANY_DETAILS_EMAIL = 'company-details-email';

export const COMPANY_DETAILS_COMPANY = 'company-details-company';
export const COMPANY_DETAILS_COMPANY__NOT_FOUND = 'not-found';
export const COMPANY_DETAILS_COMPANY_KYC = 'company-details-company-kyc';

export const COMPANY_DETAILS_EXISTING_USER_COMPANY = 'company-details-existing-user-company';
export const COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY = 'new-company';

export const COMPANY_DETAILS_CONSENT = 'company-details-consent';
export const COMPANY_DETAILS_DATA_SECURITY = 'company-details-data-security';
export const COMPANY_DETAILS_INSTITUTIONAL_NUMBERS = 'institution_identifier';
export const COMPANY_DETAILS_COMPANY_TAX_NUMBER = 'company_tax_number';

/*
We save two IBANs for some partners, a partner-specifc-iban so that the bank can find
the customer based on this iban (partner-specific-iban) and a payout IBAN where
the bank will pay the loan (bank-details-iban)
 */

export const PARTNER_SPECIFIC_IBAN = 'partner-specific-iban';
export const BANK_DETAILS_IBAN = 'bank-details-iban';
export const BANK_DETAILS_BIC = 'bank-details-bic';
export const BANK_DETAILS_ACCOUNT_OWNER = 'bank-details-account-owner';

export const VB_NUMBER = 'vb-number';
export const VM_NUMBER = 'vm-number';
export const VB_ZIP_CODE = 'vb-zip-code';

export const STRUCTURE_NUMBER = 'structure-number';
export const ADP_NUMBER = 'adp-root-number';

export const COMPANY_DETAILS_RECEIVED_SUBSIDIES_GENERAL = 'general';
export const COMPANY_DETAILS_RECEIVED_SUBSIDIES_ITEMS = 'subsidies-received';
export const COMPANY_DETAILS_RECEIVED_SUBSIDIES_GENERAL_ITEMS = 'general-subsidies-received';
export const COMPANY_DETAILS_RECEIVED_SUBSIDIES_AGRICULTURAL = 'agricultural';
export const COMPANY_DETAILS_RECEIVED_SUBSIDIES_AGRICULTURAL_ITEMS =
  'agricultural-subsidies-received';
export const COMPANY_DETAILS_RECEIVED_SUBSIDIES_FISHING = 'fishing';
export const COMPANY_DETAILS_RECEIVED_SUBSIDIES_FISHING_ITEMS = 'fishing-subsidies-received';
export const COMPANY_DETAILS_RECEIVED_SUBSIDIES_DAWI = 'dawi';
export const COMPANY_DETAILS_RECEIVED_SUBSIDIES_DAWI_ITEMS = 'dawi-subsidies-received';
export const COMPANY_DETAILS_RECEIVED_SUBSIDIES_NAME_AND_ADDRESS = 'name-and-address';
export const COMPANY_DETAILS_RECEIVED_SUBSIDIES_DATE_OF_AUTH = 'date-of-auth';
export const COMPANY_DETAILS_RECEIVED_SUBSIDIES_INSTITUTION_FILE_NUMBER = 'institution-file-number';
export const COMPANY_DETAILS_RECEIVED_SUBSIDIES_RECEIVED_AMOUNT = 'received-amount';
export const COMPANY_DETAILS_RECEIVED_SUBSIDIES_CONSENT = 'received-subsidies-consent';

/*
 * Company details - Existing user company
 */

export const EXISTING_USER_COMPANY_ALREADY_CUSTOMER = 'already-customer';
export const EXISTING_USER_COMPANY_ID = 'id';
export const EXISTING_USER_COMPANY_CITY = 'city';
export const EXISTING_USER_COMPANY_CREFO_ID = 'crefo-id';
export const EXISTING_USER_COMPANY_NAME = 'name';
export const EXISTING_USER_COMPANY_STREET = 'street';
export const EXISTING_USER_COMPANY_ZIP_CODE = 'zip-code';
export const EXISTING_USER_COMPANY_FOUNDING_YEAR = 'founding-year';
export const EXISTING_USER_COMPANY_FOUNDING_MONTH = 'founding-month';
export const EXISTING_USER_COMPANY_LEGAL_FORM = 'legal-form';
export const EXISTING_USER_COMPANY_TURNOVER_CLASS = 'turnover-class';
export const EXISTING_USER_COMPANY_INDUSTRY = 'industry';

/*
 * Leasing
 */

export const LEASING_ADVANCE_PAYMENT = 'leasing-advance-payment';

export const LEASING_TERM_IN_MONTHS = 'leasing-term-in-months';

export const LEASING_AMORTISATION_KIND = 'leasing-amortisation-kind';
export const LEASING_AMORTISATION_KIND__FULL_AMORTISATION = 'full_amortisation';
export const LEASING_AMORTISATION_KIND__PARTIAL_AMORTISATION = 'partial_amortisation';

export const LEASING_RESIDUAL_VALUE = 'leasing-residual-value';

export const LEASING_TAKEOVER = 'leasing-takeover';
export const LEASING_TAKEOVER__YES = 'yes';
export const LEASING_TAKEOVER__NO = 'no';
export const LEASING_TAKEOVER__UNDECIDED = 'undecided';

export const LEASING_DESCRIPTION = 'leasing-description';

export const LEASING_INTERESTED_IN_CREDIT_FINANCING = 'leasing-interested-in-credit-financing';

export const LEASING_INSTRESTED_IN_INSURANCE_HELPER = 'leasing-interested-in-insurance-helper';
export const LEASING_VEHICLE_INTERESTED_IN_INSURANCE = 'vehicle-interested-in-insurance';
export const LEASING_MACHINE_INTERESTED_IN_INSURANCE = 'machine-interested-in-insurance';
export const LEASING_OFFICE_EQUIPMENT_INTERESTED_IN_INSURANCE =
  'office-equipment-interested-in-insurance';

/*
 * Login
 */

export const EMAIL_FIELD = 'email';

export const PASSWORD_FIELD = 'password';
export const CONFIRM_PASSWORD_FIELD = 'confirmPassword';

/*
 * User Profile
 */
export const BFS_SERVICE_USER_INDIVIDUAL_ENTERPRISE = 'Einzelunternehmung';

export const USER_PROFILE_LEGAL_FORM = 'user-profile-legal-form';
export const BFS_SERVICE_USER_PROFILE_LEGAL_FORM = 'bfs-service-user-profile-legal-form';
export const USER_PROFILE_LEGAL_FORM__FREIBERUFLICH = 'Freiberuflich';
export const USER_PROFILE_LEGAL_FORM__GEWERBLICH = 'Gewerblich';
export const USER_PROFILE_LEGAL_FORM__GMBH = 'Gesellschaft mit beschränkter Haftung (GmbH)';
export const USER_PROFILE_LEGAL_FORM__AKTIENGESELLSCHAFT = 'Aktiengesellschaft (AG)';
export const USER_PROFILE_LEGAL_FORM__EINGETRAGENE_GENOSSENSCHAFT =
  'Eingetragene Genossenschaft (eG)';
export const USER_PROFILE_LEGAL_FORM__UNTERNEHMERGESELLSCHAFT_HAFTUNGSBESCHRANKTE =
  'Unternehmergesellschaft (haftungsbeschränkt)';
export const USER_PROFILE_LEGAL_FORM__KOMMANDITGESELLSCHAFT_AUF_AKTIEN =
  'Kommanditgesellschaft auf Aktien (KGaA)';
export const USER_PROFILE_LEGAL_FORM__GEMEINNUTZIGE_AKTIENGESELLSCHAFT =
  'Gemeinnützige Aktiengesellschaft (gAG)';
export const USER_PROFILE_LEGAL_FORM__GMBH_IN_RUNDUNG = 'GmbH in Gründung (i.G.)';
export const USER_PROFILE_LEGAL_FORM__HAFTUNGSBESCHRANKTE =
  'Haftungsbeschränkte Kommanditgesellschaft (GmbH & Co. KG)';
export const USER_PROFILE_LEGAL_FORM__KOMMANDITGESELLSCHAFT = 'Kommanditgesellschaft (KG)';
export const USER_PROFILE_LEGAL_FORM__OFFENE_HANDELSGESELLSCHAFT =
  'Offene Handelsgesellschaft (OHG)';
export const USER_PROFILE_LEGAL_FORM__GESELLSCHAFT_BURGERLICHEN_RECHTS =
  'Gesellschaft bürgerlichen Rechts (GbR)';
export const USER_PROFILE_LEGAL_FORM__PARTNERGESELLSCHAFT = 'Partnergesellschaft';
export const USER_PROFILE_LEGAL_FORM__EINGETRAGENER_VEREIN = 'Eingetragener Verein (e.V.)';
export const USER_PROFILE_LEGAL_FORM__GESELLSCHAFT_STIFTUNG_DES_PRIVATEN_RECHTS =
  'Stiftung des privaten Rechts';
export const USER_PROFILE_LEGAL_FORM__KORPERSCHAFT_DES_OFFENTLICHEN_RECHTS =
  'Körperschaft des öffentlichen Rechts';
export const USER_PROFILE_LEGAL_FORM__ANSTALT_DES_OFFENTLICHEN_RECHTS =
  'Anstalt des öffentlichen Rechts';
export const USER_PROFILE_LEGAL_FORM__EINGETRAGENER_KAUFMANN = 'Eingetragener Kaufmann (e.K.)';
export const USER_PROFILE_LEGAL_FORM__NATURLICHE_PERSON = 'natürliche Person';
export const USER_PROFILE_LEGAL_FORM__EINZELUNTERNEHMER = 'Einzelunternehmer';
export const USER_PROFILE_LEGAL_FORM__GEMEINNUTZIGE = 'gemeinnützige GmbH (gGmbH)';
export const USER_PROFILE_LEGAL_FORM__OTHER = 'Sonstige';

export const USER_PROFILE_LEGAL_FORM_DESCRIPTION = 'user-profile-legal-form-description';

export const USER_PROFILE_LEGAL_FORM__STIFTUNG_DES_OFFENTLICHEN_RECHTS =
  'Stiftung des öffentlichen Rechts';
export const USER_PROFILE_LEGAL_FORM__ORDENSGEMEINSCHAFT = 'Ordensgemeinschaft';

export const USER_PROFILE_INDUSTRY = 'user-profile-industry';
export const BFS_USER_PROFILE_INDUSTRY = 'bfs-user-profile-industry';

export const USER_PROFILE_DETAILED_ANNUAL_TURNOVER = 'user-profile-detailed-annual-turnover';
export const USER_PROFILE_BALANCE_SHEET = 'user-profile-balance-sheet';
export const USER_PROFILE_EMPLOYEE_COUNT = 'user-profile-employee-count';

export const USER_PROFILE_TURNOVER_CLASS = 'user-profile-turnover-class';
export const USER_PROFILE_TURNOVER_CLASS__0_TO_10_THOUSAND = '0_to_10_thousand';

export const USER_PROFILE_TURNOVER_CLASS__0_TO_250_THOUSAND = '0_to_250_thousand';

export const USER_PROFILE_TURNOVER_CLASS__10_TO_20_THOUSAND = '10_to_20_thousand';
export const USER_PROFILE_TURNOVER_CLASS__20_TO_30_THOUSAND = '20_to_30_thousand';
export const USER_PROFILE_TURNOVER_CLASS__30_TO_40_THOUSAND = '30_to_40_thousand';
export const USER_PROFILE_TURNOVER_CLASS__40_TO_50_THOUSAND = '40_to_50_thousand';
export const USER_PROFILE_TURNOVER_CLASS__50_TO_75_THOUSAND = '50_to_75_thousand';
export const USER_PROFILE_TURNOVER_CLASS__75_TO_100_THOUSAND = '75_to_100_thousand';
export const USER_PROFILE_TURNOVER_CLASS__100_TO_250_THOUSAND = '100_to_250_thousand';
export const USER_PROFILE_TURNOVER_CLASS__250_TO_500_THOUSAND = '250_to_500_thousand';

export const USER_PROFILE_TURNOVER_CLASS__250_THOUSAND_TO_1_MILLION = '250_thousand_to_1_million';

export const USER_PROFILE_TURNOVER_CLASS__500_THOUSAND_TO_1_MILLIOND = '500_thousand_to_1_million';
export const USER_PROFILE_TURNOVER_CLASS__1_MILLION_TO_2_5_MILLION = '1_million_to_2_5_million';
export const USER_PROFILE_TURNOVER_CLASS__2_5_TO_5_MILLION = '2_5_to_5_million';
export const USER_PROFILE_TURNOVER_CLASS__5_TO_10_MILLION = '5_to_10_million';
export const USER_PROFILE_TURNOVER_CLASS__10_TO_25_MILLION = '10_to_25_million';
export const USER_PROFILE_TURNOVER_CLASS__25_TO_50_MILLION = '25_to_50_million';
export const USER_PROFILE_TURNOVER_CLASS__50_TO_100_MILLION = '50_to_100_million';
export const USER_PROFILE_TURNOVER_CLASS__MORE_THAN_100_MILLION = 'more_than_100_million';

export const USER_PROFILE_TURNOVER_CLASS__MORE_THAN_1_MILLION_TO_5_MILLION = '1_to_5_million';
export const USER_PROFILE_TURNOVER_CLASS__MORE_THAN_5_MILLION_TO_10_MILLION = '5_to_10_million';
export const USER_PROFILE_TURNOVER_CLASS__MORE_THAN_10_MILLION = 'more_than_10_million';

export const USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS = 'annual-net-profit-or-loss-class';
export const USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__LOSS = 'loss';
export const USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__ZERO = 'zero';
export const USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__1_TO_10_THOUSAND = '1_to_10_thousand';
export const USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__10_TO_20_THOUSAND = '10_to_20_thousand';
export const USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__20_TO_30_THOUSAND = '20_to_30_thousand';
export const USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__30_TO_40_THOUSAND = '30_to_40_thousand';
export const USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__40_TO_50_THOUSAND = '40_to_50_thousand';
export const USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__50_TO_75_THOUSAND = '50_to_75_thousand';
export const USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__75_TO_100_THOUSAND =
  '75_to_100_thousand';
export const USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__100_TO_250_THOUSAND =
  '100_to_250_thousand';
export const USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__250_TO_500_THOUSAND =
  '250_to_500_thousand';
export const USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__500_TO_750_THOUSAND =
  '500_to_750_thousand';
export const USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__750_THOUSAND_TO_1_MILLION =
  '750_thousand_to_1_million';
export const USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__1_TO_2_5_MILLION = '1_to_2_5_million';
export const USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__2_5_TO_5_MILLION = '2_5_to_5_million';
export const USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__5_TO_10_MILLION = '5_to_10_million';
export const USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__10_TO_50_MILLION = '10_to_50_million';
export const USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__MORE_THAN_50_MILLION =
  'more_than_50_million';

export const USER_PROFILE_NEGATIVE_EQUITY_CAPITAL = 'negative-equity-capital';
export const USER_PROFILE_INNOVATIVE_COMPANY = 'innovative-company';
export const USER_PROFILE_ENERGY_EFFICIENT = 'energy-efficient';

export const USER_PROFILE_FOUNDING_YEAR = 'founding-year';
export const USER_PROFILE_FOUNDING_MONTH = 'founding-month';
export const USER_PROFILE_FOUNDING_TWO_YEAR_OLD = 'kfinanz_founding_year';

export const EXCLUDING_QUESTION_FIRST = 'excluding-question-first';
export const EXCLUDING_QUESTION_SECOND = 'excluding-question-second';
export const EXCLUDING_QUESTION_THIRD = 'excluding-question-third';
export const EXCLUDING_QUESTION_FOURTH = 'excluding-question-fourth';
export const EXCLUDING_QUESTION_FIFTH = 'excluding-question-fifth';

export const USER_PROFILE_COMPANY_ALREADY_CUSTOMER = 'user-profile-company-already-customer';
export const USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY =
  'user-profile-company-additional-association-company';

export const USER_PROFILE_COMPANY_NAME = 'user-profile-company-name';

export const USER_PROFILE_COMPANY_STREET = 'user-profile-company-street';

export const USER_PROFILE_COMPANY_ZIP_CODE = 'user-profile-company-zip-code';

export const USER_PROFILE_COMPANY_CITY = 'user-profile-company-city';

export const USER_PROFILE_COMPANY_RATING_KIND = 'user-profile-company-rating-kind';
export const USER_PROFILE_COMPANY_RATING_KIND__INTERNAL = 'internal';
export const USER_PROFILE_COMPANY_RATING_KIND__EXTERNAL = 'external';
export const USER_PROFILE_COMPANY_INTERNAL_RATING = 'user-profile-company-internal-rating';

/*
 * # Agricultural section
 */

export const SUBJECT_ITEM = 'subject-item';

/*
 * Offers
 */

export const SUBJECT_OFFER = 'subject-offer';
export const OFFER_PROVIDER_NAME = 'offer-provider-name';
export const OFFER_NET_PRICE = 'offer-net-price';
export const OFFER_GROSS_PRICE = 'offer-gross-price';
export const OFFER_CHOSEN = 'offer-chosen';

/*
 * Inquiry declarations
 */

export const DECLARATION_BASE = 'declaration';

/*
 * Consulting
 */

export const PRE_TAX_DEDUCTION = 'pre-tax-deduction';

export const FORM_TYPE = 'form-type';

/*
 * # UserRepresentation section
 */

export const USER_REPRESENTATION = 'user-representation' as const;
export const USER_SOLE_REPRESENTATION = 'user-shared-representation' as const;
export const USER_EXISTING_REPRESENTATIVE = 'user-existing-representative';
export const USER_REPRESENTATIVE = 'user-representative' as const;
export const USER_REPRESENTATIVES = 'user-representatives';
export const USER_REPRESENTATIVES_SELECT = 'user-representatives-select';
export const REPRESENTATIVE_GENDER = 'representative-gender';
export const REPRESENTATIVE_FIRST_NAME = 'representative-first-name';
export const REPRESENTATIVE_LAST_NAME = 'representative-last-name';
export const REPRESENTATIVE_EMAIL = 'representative-email';
export const REPRESENTATIVE_PHONE_NUMBER = 'representative-phone-number' as const;
export const REPRESENTATIVE_BIRTHDATE = 'representative-birthdate' as const;

/*
 * # Beneficiary Owners section
 */

export const BENEFICIARY_OWNER = 'beneficiary-owner' as const;
export const BENEFICIARY_OWNERS = 'beneficiary-owners';
export const BENEFICIARY_OWNERS_SELECT = 'beneficiary-owners-select';
export const HAS_BENEFICIARY_OWNER = 'has-beneficiary-owner' as const;
export const IS_BENEFICIARY_OWNER = 'is-beneficiary-owner' as const;
export const NO_BENEFICIARY_CHECKBOX = 'no-beneficiary-checkbox' as const;
export const BENEFICIARY_GENDER = 'beneficiary-gender' as const;
export const BENEFICIARY_FIRST_NAME = 'beneficiary-first-name' as const;
export const BENEFICIARY_LAST_NAME = 'beneficiary-last-name' as const;
export const BENEFICIARY_COMPANY_SHARE = 'beneficiary-company-share' as const;
export const BENEFICIARY_PRIVATE_ADDRESS = 'beneficiary-private-address' as const;
export const BENEFICIARY_TAX_ID = 'beneficiary-tax-id' as const;
export const BENEFICIARY_ZIP_CODE = 'beneficiary-zip-code' as const;
export const BENEFICIARY_PRIVATE_CITY = 'beneficiary-private-city' as const;
export const BENEFICIARY_PRIVATE_COUNTRY = 'beneficiary-private-country' as const;
export const BENEFICIARY_BIRTH_COUNTRY = 'beneficiary-birth-country' as const;
export const BENEFICIARY_BIRTHDAY = 'beneficiary-birthday' as const;
export const EXISTING_BENEFICIARY = 'existing-beneficiary' as const;
export const SELF_BENEFICIARY_OWNER = 'self-beneficiary-owner' as const;
export const FICTITIOUS_BENEFICIARY_OWNER = 'fictitious-beneficiary-owner' as const;

export const SELF_USER_REPRESENTATIVE = 'self-user-representative' as const;

/*
 * # END UserRepresentation section
 */

// Corona fields
export const CORONA_FOUNDING_YEAR = 'corona-founding-year';
export const CORONA_FOUNDING_MONTH = 'corona-founding-month';
export const CORONA_VIABILTIY = 'viability';
export const CORONA_VIABILTIY_2020 = 'viability-2020';
export const CORONA_NUMBER_OF_EMPLOYEES = 'number-of-employees';
export const CORONA_REVENUE_OF_2019 = 'revenue-2019';
export const CORONA_FINANCING_OPTION = 'financing-option';
export const CORONA_FINANCING_OPTION__QUICK_CREDIT = 'quick-credit';
export const CORONA_FINANCING_OPTION__KFW_PROGRAM = 'kfw-program';
export const CORONA_TOTAL_ASSETS = 'total-assets';
export const CORONA_EXPENDITURE_OF_EMPLOYEES = 'expenditure-of-employees';
export const CORONA_LIQUIDITY_NEED = 'corona-liquidity-need';
export const LOAN_ALREADY_AT_BANK = 'loan-already-on-hand-at-bank';

// end user fields
export const END_USER_EMAIL = 'end-user-email';
export const END_USER_FIRST_NAME = 'end-user-first-name';
export const END_USER_LAST_NAME = 'end-user-last-name';
export const END_USER_PHONE_NUMBER = 'end-user-phone-number';
export const END_USER_GENDER = 'end-user-salutation';
export const END_USER_ID = 'end-user-id';

export const END_USER_CONDITION_AMOUNT = 'end-user-condition-amount';
export const END_USER_CONDITION_INTEREST_RATE = 'end-user-condition-interest-rate';

// Non-existent in the form - just a workaround placeholder
export const USER_PROFILE_COMPANY_CREFO_ID = 'user-profile-company-onlinefactoring-id';

export const USER_PROFILE_PHONE_NUMBER = 'phone-number';
export const USER_PROFILE_PHONE_NUMBER_VERIFYER = 'phone-number-verifier';
export const SEND_PHONE_NUMBER = 'send-phone-number';
export const PHONE_PIN_INPUT = 'pin-input';

export const USER_PROFILE_BIRTHDATE = 'user-profile-birthdate';

export const USER_PROFILE_EMAIL = 'user-profile-email';

// This const was renamed because I implemented a condition so the password of the login won't be saved to the session storage,
// but for the user profile password this function this still necessary. Therefor I renamed this to passwordWithSessionStorage.
export const USER_PROFILE_PASSWORD = 'passwordWithSessionStorage';

export const USER_PROFILE_PASSWORD_REPEATED = 'password-repeated';

export const REFERRAL_CODE = 'referral-code';

export const LEAD_ID = 'lead-id';

export const MULTIPLE_ORGANISATION_ID = 'multiorganisation-id';

export const EXTERNAL_REFERENCE_ID = 'external-reference';

export const INQUIRY_DRAFT_ID = 'draft-id';

export const INCOME_SURPLUS = 'revenue-surplus-account';
export const FASTLANE_ELIGIBLE = 'fastlaneEligible';

export const OFFER_FACTORING_FEE = 'offer-factoring-fee';
export const OFFER_FACTORING_LINE = 'offer-factoring-line';
export const OFFER_INSTALLMENT_RATE = 'offer-installment-rate';
export const OFFER_MONTHLY_INSTALLMENT = 'offer-monthly-installment';
export const OFFER_PAYOUT_RATIO = 'offer-payout-ratio';

export const CONDITION_INTEREST_RATE = 'condition-interest-rate';
export const CONDITION_LAST_INSTALLMENT_AMOUNT = 'condition-last-installment-amount';
export const CONDITION_INSTALLMENT_AMOUNT = 'condition-installment-amount';

// Energy efficiency fields
export const CLIENT_ROLE = 'role';
export const CLIENT_ROLE__COMPANY = 'company';
export const CLIENT_ROLE__CONTRACTOR = 'contractor';
export const TECHNOLOGY_DESCRIPTION = 'technology-description';
export const NUMBER_OF_TECHNOLOGIES = 'number-of-technologies';
export const NUMBER_OF_TECHNOLOGIES__ONE = 'one';
export const NUMBER_OF_TECHNOLOGIES__MORE = 'several';
export const CONTRACTOR_COMPANY_INFORMATION = 'contractor-company-information';

export const MACHINE_CUSTOM_CATEGORY = 'machine-custom-category';
export const MACHINE_CUSTOM_CATEGORY__FACILITY = 'efficient_production_facility';
export const MACHINE_CUSTOM_CATEGORY__RENEWABLE_ENERGY = 'renewable_energy';
export const MACHINE_CUSTOM_CATEGORY__HEAT_OR_COLD = 'heat/cold';
export const MACHINE_CUSTOM_CATEGORY__STORAGE = 'storage';
export const MACHINE_CUSTOM_CATEGORY__ELECTROMOBILITY = 'electromobility';
export const MACHINE_CUSTOM_CATEGORY__OTHER_SYSTEMS = 'other';

// Contract Details Form Fields
export const SALES_PERSON = 'sales-person';
export const CAR_DEALER_NAME = 'dealer-name';
export const VEHICLE_DRIVE_TYPE = 'vehicle-drive-type';
export const LEASING_DURATION = 'leasing-duration';
export const LEASING_INSTALLMENT = 'leasing-installment';
export const YEARLY_MILEAGE = 'vehicle-yearly-mileage';
export const ACCOUNT_OWNER = 'account-owner';
export const IBAN = 'iban';

// Customer Data Form Fields (Lease Plan)
export const CUSTOMER_SALUTATION = 'customer-salutation';
export const CUSTOMER_FIRST_NAME = 'customer-first-name';
export const CUSTOMER_LAST_NAME = 'customer-last-name';
export const CUSTOMER_EMAIL = 'customer-email';
export const CUSTOMER_PHONE_NUMBER = 'customer-phone-number';
export const CUSTOMER_BIRTH_DATE = 'customer-birth-date';
export const CUSTOMER_COMPANY_NAME = 'customer-company-name';
export const CUSTOMER_COMPANY_ADDRESS = 'customer-company-street';

/* Further Need Section */
export const FURTHER_NEED = 'further-need';
export const FURTHER_NEED_VALUES = 'further-need-values';
export const OTHER_FINANCING_SOLUTIONS = 'other-financing-solutions';
export const PUBLIC_FUNDING = 'public-funding';
export const EXPERT_ADVICE_ON_ALL = 'expert-advice-on-all';
export const PAYMENT_TRANSACTIONS = 'payment-transactions';
export const INVESTMENT_AND_SECURITIES = 'investment-and-securities';
export const FUNDRAISING = 'fundraising';
export const FUNDING_CLOSING_DATE = 'funding-closing-date';

/* bfs contact person section */
export const CONTACT_PERSON_GENDER = 'person-gender';
export const CONTACT_PERSON_FIRST_NAME = 'person-first-name';
export const CONTACT_PERSON_LAST_NAME = 'person-last-name';
export const CONTACT_PERSON_EMAIL = 'person-email';
export const CONTACT_PERSON_PHONE = 'person-phone-no';

export const CONTACT_PERSON_SECURITY_CONSENT = 'person-security-consent';
export const CONTACT_PERSON_OFFER_CHECK = 'person-offer-check';

export const CURRENT_USAGE_MARKET_VALUES = 'current-usage-market-values';
export const FUTURE_USAGE_MARKET_VALUES = 'future-usage-market-values';
export const CURRENT_USAGE_MARKET_VALUE_PER_SQM_UNIT =
  'current-usage-market-values-market-value-per-sqm-unit';
export const CURRENT_USAGE_REE_VALUE_PER_SQM_UNIT = 'current-usage-ree-value-per-sqm-unit';
export const CURRENT_USAGE_MARKET_VALUE_PER_SQM_BUILDING =
  'current-usage-market-values-market-value-per-sqm-building';
export const CURRENT_USAGE_REE_VALUE_PER_SQM_BUILDING = 'current-usage-ree-value-per-sqm-building';
export const CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM =
  'current-usage-market-values-residential-rent-per-sqm';
export const CURRENT_USAGE_REE_RESIDENTIAL_RENT_PER_SQM =
  'current-usage-ree-residential-rent-per-sqm';
export const CURRENT_USAGE_EXISTING_RENT_PER_SQM =
  'current-usage-market-values-existing-rent-per-sqm';
export const CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM =
  'current-usage-market-values-commercial-rent-per-sqm';
export const CURRENT_USAGE_REE_COMMERCIAL_RENT_PER_SQM =
  'current-usage-ree-commercial-rent-per-sqm';
export const CURRENT_USAGE_REE_PARKING_LOT_VALUE =
  'current-usage-market-values-market-value-parking-lot';
export const CURRENT_USAGE_REE_GARAGE_VALUE = 'current-usage-ree-garage-value';
export const CURRENT_USAGE_REE_GARAGE_RENT = 'current-usage-ree-garage-rent';
export const CURRENT_USAGE_REE_PARKING_LOT_RENT = 'current-usage-ree-parking-lot-rent';

export const FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT =
  'future-usage-market-values-market-value-per-sqm-unit';
export const FUTURE_USAGE_MARKET_VALUE_PER_SQM_WHG_K =
  'future-usage-market-values-market-value-per-sqm-whg-k';
export const FUTURE_USAGE_REE_VALUE_PER_SQM_UNIT = 'future-usage-ree-value-per-sqm-unit';
export const FUTURE_USAGE_MARKET_VALUE_PER_SQM_BUILDING =
  'future-usage-market-values-market-value-per-sqm-building';
export const FUTURE_USAGE_REE_VALUE_PER_SQM_BUILDING = 'future-usage-ree-value-per-sqm-building';
export const FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM =
  'future-usage-market-values-residential-rent-per-sqm';
export const FUTURE_USAGE_REE_RESIDENTIAL_RENT_PER_SQM =
  'future-usage-ree-residential-rent-per-sqm';
export const FUTURE_USAGE_EXISTING_RENT_PER_SQM =
  'future-usage-market-values-existing-rent-per-sqm';
export const FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM =
  'future-usage-market-values-commercial-rent-per-sqm';
export const FUTURE_USAGE_REE_COMMERCIAL_RENT_PER_SQM = 'future-usage-ree-commercial-rent-per-sqm';
export const FUTURE_USAGE_REE_PARKING_LOT_VALUE = 'future-usage-ree-parking-lot-value';
export const FUTURE_USAGE_REE_GARAGE_VALUE = 'future-usage-ree-garage-value';
export const FUTURE_USAGE_REE_GARAGE_RENT = 'future-usage-ree-garage-rent';
export const FUTURE_USAGE_REE_PARKING_LOT_RENT = 'future-usage-ree-parking-lot-rent';

export const COMMERCIAL_RENT_PER_SQM = 'commercial-rent-per-sqm';
export const EXISTING_RENT_PER_SQM = 'existing-rent-per-sqm';
export const RESIDENTIAL_RENT_PER_SQM = 'residential-rent-per-sqm';
// planning and evaluation (market-data, realstate)
export const USAGE_TYPE = 'usage-type';
export const SALE_TYPE = 'sale-type';
export const FINANCING_ROLE = 'financing-role';
export const MARKET_DATA_ADDRESS_SELECTION = 'address-selection';

// fields for profitability editing
// lending values
export const PROFITABILITY_SAFETY_MARKDOWN = 'profitability-safety-markdown';
export const PROFITABILITY_EXTRA_MARKDOWN = 'profitability-extra-markdown';
// profitability investment costs start
export const PROFITABILITY_PURCHASE_PRICE = 'profitability-purchase-price';
export const PROFITABILITY_LEGAL_FEES = 'profitability-legal-fees';
export const PROFITABILITY_AGENT_FEES = 'profitability-agent-fees';
export const PROFITABILITY_REAL_ESTATE_TRANSFER_TAX = 'profitability-real-estate-transfer-tax';
export const PROFITABILITY_MEASUREMENT_COSTS = 'profitability-measurement-costs';
export const PROFITABILITY_OTHER_FEES = 'profitability-other-fees';
export const PROFITABILITY_OTHER_PURCHASE_COSTS = 'profitability-other-purchase-costs';
export const PROFITABILITY_OTHER_COSTS = 'profitability-other-costs';
export const PROFITABILITY_COMPENSATIONS = 'profitability-compensations';
export const PROFITABILITY_LAND_DEVELOPMENT_COSTS = 'profitability-land-development-costs';
export const PROFITABILITY_PUBLIC_DISPOSAL = 'profitability-public-disposal';
export const PROFITABILITY_NON_PUBLIC_DISPOSAL = 'profitability-non-public-disposal';
export const PROFITABILITY_OTHER_DEVELOPMENT_COSTS = 'profitability-other-development-costs';
export const PROFITABILITY_CONSTRUCTION_COSTS = 'profitability-construction-costs';
export const PROFITABILITY_OUTDOOR_FACILITIES_COSTS = 'profitability-outdoor-facilities-costs';
export const PROFITABILITY_OTHER_CONSTRUCTION_COSTS = 'profitability-other-construction-costs';
export const PROFITABILITY_ARCHITECT_COSTS = 'profitability-architect-costs';
export const PROFITABILITY_ADDITIONAL_COSTS = 'profitability-additional-costs';
export const PROFITABILITY_THIRD_PARTY_FINANCING_COSTS =
  'profitability-third-party-financing-costs';
export const PROFITABILITY_FINANCING_COSTS_INTERESTS_MANUAL =
  'profitability-financing-costs-interests-manual';
export const PROFITABILITY_FINANCING_COSTS_FEES_MANUAL =
  'profitability-financing-costs-fees-manual';
export const PROFITABILITY_BUFFER = 'profitability-buffer';
export const PROFITABILITY_MARKETING_SALES_COSTS = 'profitability-marketing-sales-costs';
// profitability investment costs end

// fields for profitability editing presales data START
export const PROFITABILITY_PRESALES_AREA_RESIDENTIAL = 'profitability-presales-area-residential';
export const PROFITABILITY_PRESALES_PRICE_RESIDENTIAL_PER_SQM =
  'profitability-presales-price-residential-per-sqm';
export const PROFITABILITY_PRESALES_AREA_COMMERCIAL = 'profitability-presales-area-commercial';
export const PROFITABILITY_PRESALES_PRICE_COMMERCIAL_PER_SQM =
  'profitability-presales-price-commercial-per-sqm';
export const PROFITABILITY_NUMBER_OF_PARKING_LOTS = 'profitability-number-of-parking-lots';
export const PROFITABILITY_PRESALES_PRICE_PARKING_LOT_PER_PIECE =
  'profitability-presales-price-parking-lot-per-piece';
export const PROFITABILITY_NUMBER_OF_GARAGES = 'profitability-number-of-garages';
export const PROFITABILITY_PRESALES_PRICE_GARAGE_PER_PIECE =
  'profitability-presales-price-garage-per-piece';
// fields for profitability editing presales END
// fields for profitability financing parameters START
export const PROFITABILITY_FINANCING_FEE_BUILDING = 'profitability-financing-fee-building';
export const PROFITABILITY_FINANCING_DURATION_BUILDING =
  'profitability-financing-duration-building';
export const PROFITABILITY_INTEREST_RATE_BUILDING = 'profitability-interest-rate-building';
export const PROFITABILITY_FINANCING_FEE_UNIT = 'profitability-financing-fee-unit';
export const PROFITABILITY_FINANCING_DURATION_UNIT = 'profitability-financing-duration-unit';
export const PROFITABILITY_INTEREST_RATE_UNIT = 'profitability-interest-rate-unit';
export const PROFITABILITY_FINANCING_FEE_IN_PERCENT_RENT =
  'profitability-financing-fee-in-percent-rent';
export const PROFITABILITY_FINANCING_DURATION_RENT = 'profitability-financing-duration-rent';
export const PROFITABILITY_INTEREST_RATE_RENT = 'profitability-interest-rate-rent';
export const PROFITABILITY_INTEREST_RATE_RENT_SHORT = 'profitability-interest-rate-rent-short';
export const PROFITABILITY_REPAYMENT_RATE_RENT = 'profitability-repayment-rate-rent';
export const PROFITABILITY_FINANCING_ADDITIONAL_NOTE = 'profitability-financing-additional-note';
// fields for profitability financing parameters END

// fields for profitability bank values data Start
export const CURRENT_USAGE_SQM_PRICE_RESIDENTIAL = 'current-usage-sqm-price-residential';
export const CURRENT_USAGE_SQM_PRICE_COMMERCIAL = 'current-usage-sqm-price-commercial';
export const CURRENT_USAGE_PRICE_GARAGE_PARKING = 'current-usage-price-garage-parking';
export const CURRENT_USAGE_PRICE_OUTSIDE_PARKING = 'current-usage-price-outside-parking';
export const CURRENT_USAGE_SQM_PRICE_PROPERTY = 'current-usage-sqm-price-property';
export const CURRENT_USAGE_FAIR_MARKET_VALUE = 'current-usage-fair-market-value';
export const CURRENT_USAGE_LOAN_VALUE = 'current-usage-loan-value';
export const CURRENT_USAGE_SQM_RENT_RESIDENTIAL = 'current-usage-sqm-rent-residential';
export const CURRENT_USAGE_SQM_RENT_COMMERCIAL = 'current-usage-sqm-rent-commercial';
export const CURRENT_USAGE_RENT_GARAGE_PARKING = 'current-usage-rent-garage-parking';
export const CURRENT_USAGE_RENT_OUTSIDE_PARKING = 'current-usage-rent-outside-parking';
export const FUTURE_USAGE_SQM_PRICE_RESIDENTIAL = 'future-usage-sqm-price-residential';
export const FUTURE_USAGE_SQM_PRICE_COMMERCIAL = 'future-usage-sqm-price-commercial';
export const FUTURE_USAGE_PRICE_GARAGE_PARKING = 'future-usage-price-garage-parking';
export const FUTURE_USAGE_PRICE_OUTSIDE_PARKING = 'future-usage-price-outside-parking';
export const FUTURE_USAGE_SQM_PRICE_PROPERTY = 'future-usage-sqm-price-property';
export const FUTURE_USAGE_FAIR_MARKET_VALUE = 'future-usage-fair-market-value';
export const FUTURE_USAGE_LOAN_VALUE = 'future-usage-loan-value';
export const FUTURE_USAGE_SQM_RENT_RESIDENTIAL = 'future-usage-sqm-rent-residential';
export const FUTURE_USAGE_SQM_RENT_COMMERCIAL = 'future-usage-sqm-rent-commercial';
export const FUTURE_USAGE_RENT_GARAGE_PARKING = 'future-usage-rent-garage-parking';
export const FUTURE_USAGE_RENT_OUTSIDE_PARKING = 'future-usage-rent-outside-parking';
// fields for profitability bank values data END

// fields for profitability bank values additional information Start
export const CURRENT_USAGE_OPERATING_COSTS = 'current-usage-operating-costs';
export const CURRENT_USAGE_OPERATING_COSTS_ESTIMATION = 'current-usage-operating-costs-estimation';
export const CURRENT_USAGE_OPERATING_COSTS_MINIMUM = 'current-usage-operating-costs-minimum';
export const FUTURE_USAGE_OPERATING_COSTS = 'future-usage-operating-costs';
export const FUTURE_USAGE_OPERATING_COSTS_ESTIMATION = 'future-usage-operating-costs-estimation';
export const FUTURE_USAGE_OPERATING_COSTS_MINIMUM = 'future-usage-operating-costs-minimum';
export const FUTURE_USAGE_VACANCY = 'future-usage-vacancy';
export const FUTURE_USAGE_REMAINING_USAGE_DURATION = 'future-usage-remaining-usage-duration';
export const FUTURE_USAGE_PRESALES_RATE = 'future-usage-presales-rate';
export const FUTURE_USAGE_VISITED_DATE = 'future-usage-visited-date';
export const FUTURE_USAGE_VISITED_BY = 'future-usage-visited-by';
// fields for profitability bank values additional information END

// fields for profitability financing criteria START
export const CUSTOMER_GRADE = 'customer-grade';
export const CUSTOMER_TYPE = 'customer-type';
export const LOCATION_GRADE = 'location-grade';
export const LOCATION_GRADE_RECOMMENDATION = 'location-grade-recommendation';
export const NOTES_ON_FINANCING_CRITERIA = 'notes-on-financing-criteria';
// fields for profitability financing criteria END

// fields for mittweida profitability calculation START
// FINANCING DETAILS START
export const FINANCING_DETAILS_FEE_DURATION = 'financing_details_fee_duration';
export const FINANCING_DETAILS_FEE_RATE = 'financing_details_fee_rate';
export const FINANCING_DETAILS_FINANCING_DURATION = 'financing_details_financing_duration';
export const FINANCING_DETAILS_INTEREST_RATE = 'financing_details_interest_rate';
export const FINANCING_DETAILS_NOTES_ON_FINANCING_PARAMETERS =
  'financing_details_notes_on_financing_parameters';
export const FINANCING_DETAILS_REPAYMENT_RATE = 'financing_details_repayment_rate';
// FINANCING DETAILS END
// INVESTMENT PLAN START
export const INVESTMENT_PLAN_ADDITIONAL_COSTS = 'investment_plan_additional_costs';
export const INVESTMENT_PLAN_CONSTRUCTION_COSTS = 'investment_plan_construction_costs';
export const INVESTMENT_PLAN_GAK_ADDITIONAL_COSTS = 'investment_plan_gak_additional_costs';
export const INVESTMENT_PLAN_GAK_BUILDING_COSTS = 'investment_plan_gak_building_costs';
export const INVESTMENT_PLAN_GAK_BUYING_COSTS = 'investment_plan_gak_buying_costs';
export const INVESTMENT_PLAN_GAK_INTEREST_COSTS = 'investment_plan_gak_interest_costs';
export const INVESTMENT_PLAN_GAK_OTHER = 'investment_plan_gak_other';
export const INVESTMENT_PLAN_GAK_SELLING_COSTS = 'investment_plan_gak_selling_costs';
export const INVESTMENT_PLAN_INTEREST_COSTS = 'investment_plan_interest_costs';
export const INVESTMENT_PLAN_OTHER_COSTS = 'investment_plan_other_costs';
export const INVESTMENT_PLAN_SELLING_COSTS = 'investment_plan_selling_costs';
// INVESTMENT PLAN END
// SOURCE OF FUNDS START
export const SOURCE_OF_FUNDS_EQUITY_GRANTS = 'source_of_funds_equity_grants';
export const SOURCE_OF_FUNDS_EQUITY_RELEASE = 'source_of_funds_equity_release';
export const SOURCE_OF_FUNDS_GAK_EQUITY_SHARE_BUYING_COSTS =
  'source_of_funds_gak_equity_share_buying_costs';
export const SOURCE_OF_FUNDS_GAK_LOAN = 'source_of_funds_gak_loan';
export const SOURCE_OF_FUNDS_LOAN_PROJECT = 'source_of_funds_loan_project';
export const SOURCE_OF_FUNDS_NOTES_ON_FINANCING_PLAN = 'source_of_funds_notes_on_remaining_risk';
// SOURCE OF FUNDS END
// PROFITABILITY SALES START
export const PROFITABILITY_SALES_BUYING_COSTS_CHECKED = 'profitability_sales_buying_costs_checked';
export const PROFITABILITY_SALES_CONSTRUCTION_COSTS_CHECKED =
  'profitability_sales_construction_costs_checked';
export const PROFITABILITY_SALES_SALES_PRICES_CHECKS = 'profitability_sales_sales_prices_checks';
// PROFITABILITY SALES END
// RENT START
export const RENT_CUSTOMER_NOW_ASSET_SURPLUS = 'rent_customer_now_asset_surplus';
export const RENT_CUSTOMER_NOW_EQUITY_PROOF = 'rent_customer_now_equity_proof';
export const RENT_CUSTOMER_NOW_FREE_INCOME = 'rent_customer_now_free_income';
export const RENT_CUSTOMER_NOW_OPERATING_COSTS = 'rent_customer_now_operating_costs';
export const RENT_CUSTOMER_PLAN_OPERATING_COSTS = 'rent_customer_plan_operating_costs';
export const RENT_NOTES_ON_DEBT_SERVICE = 'rent_notes_on_debt_service';
export const RENT_CURRENT_OPERATING_COSTS_ESTIMATION_CUSTOMER =
  'rent_current_operating_costs_estimation_customer';
export const RENT_CURRENT_OPERATING_COSTS_MIN_CUSTOMER =
  'rent_current_operating_costs_min_customer';
export const RENT_FUTURE_OPERATING_COSTS_ESTIMATION_CUSTOMER =
  'rent_future_operating_costs_estimation_customer';
export const RENT_FUTURE_OPERATING_COSTS_MIN_CUSTOMER = 'rent_future_operating_costs_min_customer';
// RENT END
// LENDING VALUE START
export const LENDING_VALUE_BUYING_PHASE_LENDING_LIMIT_PERCENT =
  'lending_value_buying_phase_lending_limit_percent';
export const LENDING_VALUE_BUYING_PHASE_RISK_REDUCTION =
  'lending_value_buying_phase_risk_reduction';
export const LENDING_VALUE_INDICATION_LENDING_LIMIT_PERCENT =
  'lending_value_indication_lending_limit_percent';
export const LENDING_VALUE_WHOLE_PROJECT_LENDING_LIMIT_PERCENT =
  'lending_value_whole_project_lending_limit_percent';
export const LENDING_VALUE_WHOLE_PROJECT_RISC_DEDUCTION =
  'lending_value_whole_project_risc_deduction';
export const LENDING_VALUE_NOTES_ON_REMAINING_RISK = 'lending_value_notes_on_remaining_risk';
// LENDING VALUE END
// fields for mittweida profitability calculation END

// fields for profitability rental parameters Start
export const PROFITABILITY_OPERATING_COSTS_RESIDENTIAL =
  'profitability-operating-costs-residential';
export const PROFITABILITY_OPERATING_COSTS_COMMERCIAL = 'profitability-operating-costs-commercial';
export const PROFITABILITY_DEPRECIATIONS_PER_YEAR_IN_EURO =
  'profitability-depreciations-per-year-in-euro';
export const PROFITABILITY_TAX_RATE_IN_PERCENT = 'profitability-tax-rate-in-percent';
// fields for profitability rental parameters End

// BFS Service fields start
export const BFS_SERVICE__PURPOSE_KIND = 'industry';
// BFS Service Purpose Kind options
export const BFS_SERVICE__PURPOSE_KIND_OCCUPATIONAL_THERAPIST = 'occupational_therapists';
export const BFS_SERVICE__PURPOSE_KIND_MIDWIVES = 'midwives';
export const BFS_SERVICE__PURPOSE_KIND_HOMECARE_SERVICE = 'homecare_service';
export const BFS_SERVICE__PURPOSE_KIND_HEARING_AID_ACOUSTICS = 'hearing_aid_acoustics';
export const BFS_SERVICE__PURPOSE_KIND_PATIENT_TRANSPORT = 'patient_transport';
export const BFS_SERVICE__PURPOSE_KIND_SPEECH_THERAPY = 'speech_therapy';
export const BFS_SERVICE__PURPOSE_KIND_MEDICAL_TECHNOLOGY = 'medical_technology';
export const BFS_SERVICE__PURPOSE_KIND_ORTHOPEDICS = 'orthopedics';
export const BFS_SERVICE__PURPOSE_KIND_PODOLOGY = 'podology';
export const BFS_SERVICE__PURPOSE_KIND_PHYSIOTHERAPY = 'physiotherapy';
export const BFS_SERVICE__PURPOSE_KIND_REHABILITATION_PHYSIOTHERAPY =
  'rehabilitation_physiotherapy';
export const BFS_SERVICE__PURPOSE_KIND_MEDICAL_SUPPLY_STORES = 'medical_supply_stores';
export const BFS_SERVICE__PURPOSE_KIND_OUTPATIENT_CARE_SERVICES = 'outpatient_care_services';
export const BFS_SERVICE__PURPOSE_KIND_CARE_AND_RESPITE_SERVICES = 'care_and_respite_services';
export const BFS_SERVICE__PURPOSE_KIND_INTENSIVE_CARE = 'intensive_care';
export const BFS_SERVICE__PURPOSE_KIND_YOUTH_AID = 'youth_aid';
export const BFS_SERVICE__PURPOSE_KIND_HOSPITALS = 'hospitals';
export const BFS_SERVICE__PURPOSE_KIND_REHABILITATION_CLINICS_AND_CENTERS =
  'rehabilitation_clinics_and_centers';
export const BFS_SERVICE__PURPOSE_KIND_RESCUE_SERVICES = 'rescue_services';
export const BFS_SERVICE__PURPOSE_KIND_DAY_CARE = 'day_care';
export const BFS_SERVICE__PURPOSE_KIND_RESIDENTIAL_COMMUNITIES = 'residential_communities';
export const BFS_SERVICE__PURPOSE_KIND_SOCIOTHERAPISTS = 'sociotherapists';
export const BFS_SERVICE__PURPOSE_KIND_OTHER = 'other';

export const BFS_SERVICE__COMPANY_OTHER_INDUSTRY = 'other-industry';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING = 'industry-software-for-billing';
export const BFS_SERVICE__OTHER_INDUSTRY_SOFTWARE_FOR_BILLING =
  'other-industry-software-for-billing';

export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_BEHINDERTEN_VERBAND =
  'Behinderten- und Rehabilitationssport Verband RLP e.V.';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_MEDIFOX_DAN = 'MediFox/Dan';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_CURA_SOFT = 'CuraSoft';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_DEAL_SOFT = 'dealSoft';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_DMRZ = 'DMRZ';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_SNAP = 'Snap/Euregon';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_NOVENTI_CARE = 'Noventi Care';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_CARE_SOCIAL = 'Care Social';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_MULTI_COM = 'Multicom';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_HY_CARE = 'Hy Care';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_KEINE = 'Keine';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_STARKE =
  'Starke Software Buchner und Partner GmbH';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_MPC = 'MPC Software';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_TAXI = 'Taxi.de';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_SUE = 'SuE-Software GmbH';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_TRANSDATA =
  'Transdata Abrechnungsservice UG';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_ST_TECHNIC = 'S+T Software Technic GmbH';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_TOP_M = 'TopM Software GmbH';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_YOSHTEQ_SOLUTIONS =
  'Yoshteq-Software Solutions Service';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_BV_SOZIOTHERAPEUTEN =
  'BV der Soziotherapeuten e.V.';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_REHA_SPORT = 'RehaSport Deutschland e.V.';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_AEO_ABRECHNUNGSSSYSTEME =
  'A.E.O. Abrechnungssysteme';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_ATS_WINDIA = 'ATS WinDIA';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_PFLEGE_DIGITAL = 'PflegeDigital';
export const BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_OTHER = 'Sonstiges';

export const BFS_SERVICE__PURCHASED_INVOICE_PAYMENT_BY = 'purchased-invoice-payment-by';
export const BFS_SERVICE__MONTHLY_TURNOVER = 'factoring-sales-gross';
export const PRE_FINANCING_PERIOD = 'pre-financing-period';
export const BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON = 'purchased-invoices-paid-out-time';
export const BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_INSTANT = 'instant_payout';
export const BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_24_HOURS = 'after_24_hours';
export const BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_3_DAYS = 'after_3_days';
export const BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_5_DAYS = 'after_5_days';
export const BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_7_DAYS = 'after_7_days';
export const BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_10_DAYS = 'after_10_days';
export const BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_15_DAYS = 'after_15_days';
export const BFS_SERVICE__USE_FACTORING_AS_FINANCING = 'use-factoring-form';
export const BFS_SERVICE__ALREADY_ASSIGNED_RECEIVABLES = 'claims-assigned-already';
export const BFS_SERVICE__FACTORING_PROVIDER_CUSTOMER = 'billing-service-provider';
export const BFS_SERVICE__EXPLICIT_NEED_FURTHER_ADVICE = 'explicit_need_further_advice';
export const BFS_SERVICE__EXPLICIT_NEED_OTHER_FINANCING_SOLUTIONS =
  'explicit_need_other_financing_solutions';
export const BFS_SERVICE__EXPLICIT_NEED_EXPERT_ADVICE = 'explicit_need_expert_advice';
export const BFS_SERVICE__EXPLICIT_NEED_CURRENT_ACCOUNT_AND_PAYMENT =
  'explicit_need_current_account_payment';
export const BFS_SERVICE__EXPLICIT_NEED_BILLING_SOFTWARE = 'explicit_need_billing_software';
export const BFS_SERVICE__EXPLICIT_NEED_FACTORING_EXCHANGE_SERVICE =
  'explicit_need_factoring_exchange_service';
export const BFS_SERVICE__EXPLICIT_NEED_SEMINARS = 'explicit_need_seminars';
export const BFS_SERVICE__FACTORING_OFFER = 'source-of-information';
export const BFS_SERVICE__FACTORING_OFFER_GOOGLE_ADVERTISING = 'google-advertising';
export const BFS_SERVICE__FACTORING_OFFER_RECOMMENDATION = 'recommendation';
export const BFS_SERVICE__FACTORING_OFFER_BFS = 'bfs';
export const BFS_SERVICE__FACTORING_ASSOCIATION = 'association';
export const BFS_SERVICE__FACTORING_OFFER_SOFTWARE_PARTNER = 'software-partner';
export const BFS_SERVICE__FACTORING_OFFER_OTHER = 'other';
export const BFS_SERVICE__SOURCE = 'other-source-of-information';
export const SPECIAL_FEATURES = 'special-features';
// BFS Service fields end

// QUICK CHECK FORM FIELDS START

// CUSTOMER INFORMATION
export const QUICK_CHECK_PREVIOUS_BUSINESS_RELATIONSHIPS =
  'informationOnPreviousBusinessRelatioship';
export const QUICK_CHECK_INITIATOR = 'informationAboutInitiator';
export const QUICK_CHECK_STATUS_OF_CURRENT_PROJECT_FINANCING_IN_HOUSE =
  'statusOfCurrentProjectFinancingInhouse';
export const QUICK_CHECK_FINANCIAL_CURCUMSTANCES_OF_CUSTOMER = 'financialCurcumstancesOfCustomer';
// PROJECT INFORMATION
export const QUICK_CHECK_PROJECT_DESCRIPTION = 'quick_check_project_description';
export const QUICK_CHECK_INFO_ON_COLLATERAL = 'quick_check_info_on_collateral';
export const QUICK_CHECK_WITHDRAWAL_REQUIREMENTS = 'quick_check_withdrawal_requirements';
// OTHER INFORMATION
export const QUICK_CHECK_OTHER_NOTICES_AND_INFORMATION = 'otherNoticesAndInformation';
export const QUICK_CHECK_JUSTIFICATION_TEMPLATE = 'justificationTemplate';
// CREDIT VALUE
export const QUICK_CHECK_TYPE_AND_GUARANTOR = 'quick_check_type_and_guarantor';
export const QUICK_CHECK_AMOUNT_IN_EURO = 'quick_check_amount_in_euro';
// BLANK PART
export const QUICK_CHECK_CURRENT_TOTAL_COMMITMENT = 'quick_check_current_total_commitment';
export const QUICK_CHECK_NEW_COMMITMENT = 'quick_check_new_commitment';
export const QUICK_CHECK_CURRENT_COLLATERAL_VALUE = 'quick_check_current_collateral_value';
export const QUICK_CHECK_NEW_COLLATERAL = 'quick_check_new_collateral';
// PROJECT CALCULATION
export const QUICK_CHECK_SELECTING_PROFITABILITY_TYPE = 'quick_check_selecting_profitability_type';
export const QUICK_CHECK_SELECTING_PROFITABILITY_ROLE = 'quick_check_selecting_profitability_role';
export const QUICK_CHECK_ROLES_PROPERTY_DEVELOPER = 'quick_check_roles_property_developer';
export const QUICK_CHECK_ROLES_PARTITION = 'quick_check_roles_partition';
export const QUICK_CHECK_ROLES_FILE_HALTER = 'quick_check_roles_file_halter';

// QUICK CHECK FORM FIELDS END

/*
 * leaseplan custom
 */

export const UPLOAD_DOCUMENTS_BUTTON = 'upload-documents-button';
export const SIGN_CONTRACT_BUTTON = 'sign-contract-button';

/*
 * navigation
 */

export const NAVIGATION_DASHBOARD_LINK = 'dashboard-link';
export const NAVIGATION_DETAILS_LINK = 'details-link';
export const NAVIGATION_DOCUMENTS_LINK = 'documents-link';
export const NAVIGATION_BACK_BUTTON = 'navigation-back-button';
